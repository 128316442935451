import React from 'react';
import "../../styles/Result/ResultHeader.scss"

function ResultHeader({answerNumber}) {
  let mattresses = 'MATERAC'
  if (answerNumber === 1) {
    mattresses = 'MATERAC'
  } else if (answerNumber >= 2 && answerNumber <= 4) {
    mattresses = 'MATERACE'
  } else if (answerNumber >= 5 && answerNumber <= 21) {
    mattresses = 'MATERACY'
  } else if (answerNumber >= 22 && answerNumber <= 24) {
    mattresses = 'MATERACE'
  } else if (answerNumber > 25) {
    mattresses = 'MATERACY'
  }
  return (
    <div className="configurator-result-header">
      <h2 className="configurator-result-header__heading">
        ZNALEŹLIŚMY DLA CIEBIE {answerNumber} {mattresses}
      </h2>
    </div>
  );
}

export default ResultHeader;