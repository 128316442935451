import * as React from "react";

function SvgIconInfoOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
      <path
        d="M11.25 6.25h2.5v2.5h-2.5zm0 5h2.5v7.5h-2.5zM12.5 0A12.5 12.5 0 1025 12.5 12.5 12.5 0 0012.5 0zm0 22.5a10 10 0 1110-10 10.013 10.013 0 01-10 10z"
        fill="#8e95a5"
      />
    </svg>
  );
}

export default SvgIconInfoOutline;
