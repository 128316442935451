import React from 'react';
import "../../styles/Result/ResultDescription.scss";
import ResultParagraph from "./ResultParagraph";

function ResultDescription() {
  return (
    <>
      <div className="configurator-result-description">
        <img src="./icons/icon-info-outline.svg" className="configurator-result-description__info-icon" viewBox="0 0 25 25" alt=""/>
        <p className="configurator-result-description__info">
          Wskazówki dla osób, które są zainteresowane zakupem jednego szerokiego materaca dla dwóch osób śpiących:
        </p>
      </div>
      <div className="configurator-result-description__paragraph-section">
        <ResultParagraph
          description={"Ze względu na większe obciążenie materaca przez dwie osoby zalecamy wybierać nieco twardszy materac niż wynikałoby to z wyboru dla jednej osoby."}/>
        <ResultParagraph
          description={"Jeśli występuje duża różnica wagi osób śpiących (>20 kg) zalecamy zakup dwóch tych samych materacy o różnych twardościach wkładu (H2, H3, H4) lub o zróżnicowanych twardościach stron"}/>
        <ResultParagraph
          description={"Jeśli jednak jesteście Państwo zdecydowani na zakup jednego szerokiego materaca dla dwóch osób śpiących pomimo dużej różnicy wagi (>20kG), to zalecamy dobrać materac wlaściwy dla osoby cięższej i zakup komfortowego toppera, który poprawi komfort osobie lżejszej"}/>
      </div>
    </>
  );
}

export default ResultDescription;