import React from 'react';
import "../../styles/Result/ResultCard.scss"
import {parsePriceToPln} from "../../lib/parser";

function ResultCard({id, imageUrl, name, description, rating, price, discountPrice, url: pageUrl}) {
  return (
    <div className="configurator-result-card" key={id}>
      <img className="configurator-result-card__image" src={imageUrl} alt=""/>
        <div className="configurator-result-card__content">
            {/*<p className="configurator-result-card__feedback">Opinie: ({rating.count})
          {[...Array(rating.stars)].map((index) => <StarIcon key={index}
                                                             className={"configurator-result-card__star-icon configurator-result-card__star-icon--active-star"}/>)}
          {[...Array(5 - rating.stars)].map((index) => <StarIcon key={index}
                                                                 className={"configurator-result-card__star-icon"}/>)}
        </p>*/}
            <h3 className="configurator-result-card__title">{name}</h3>
            <p className="configurator-result-card__description">{description}</p>
        </div>
        <div
            className={discountPrice ? "configurator-result-card__price result-card__price--discount" : "configurator-result-card__price"}>od {parsePriceToPln(price)} zł
        </div>
        {discountPrice &&
            <div className="configurator-result-card__discount-price">od {parsePriceToPln(discountPrice)} zł</div>}
        <div className="configurator-result-card__button-section">
            <a className="configurator-result-card__button" target="_blank" href={pageUrl} rel="noreferrer">
                Kup produkt
            </a>
        </div>
    </div>
  );
}

export default ResultCard;
