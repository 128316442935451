import React from 'react'
import "../../styles/Question/QuestionFooter.scss"
import {Alert} from '@material-ui/lab'
import SvgIconArrowLeft from './../Icons/IconArrowLeft'
import SvgIconArrowRight from './../Icons/IconArrowRight'

function QuestionFooter({onNextStep, onPreviousStep, activeStep, totalQuestionsCount, onFormSubmit, question, isAlertShowed, answerSelections, selectedAnswer}) {
  return (
    <>
      <div className="configurator-question-footer">
        {activeStep !== 1 &&
        <button onClick={() => onPreviousStep()} className="configurator-question-footer__button configurator-question-footer__button--previous">
          <SvgIconArrowLeft />Wstecz</button>}
        <button
          onClick={() => activeStep === totalQuestionsCount ? onFormSubmit() : onNextStep()}
          className="configurator-question-footer__button configurator-question-footer__button--next">{activeStep === totalQuestionsCount ? "Wyszukaj" : "Dalej"}<SvgIconArrowRight />
        </button>
      </div>
    </>
  );
}

export default QuestionFooter;