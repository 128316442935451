import React from "react";
import { Alert } from "@material-ui/lab";

import QuestionHeader from "./QuestionHeader";
import QuestionBody from "./QuestionBody";
import QuestionFooter from "./QuestionFooter";

import "../../styles/Question/Question.scss";

function Question({
  answerSelection,
  isActive,
  id,
  displayOrder,
  description,
  question,
  answers,
  onAnswerSelected,
  onNextStep,
  onPreviousStep,
  activeStep,
  totalQuestionsCount,
  onFormSubmit,
  isAlertShowed,
  answerSelections,
}) {
  const selectedAnswer = answerSelection
    ? answers.find((answer) => answer.id === answerSelection.answerId)
    : undefined;

  const showError = () => {
    const generalError = (
      <Alert
        className="configurator-question-footer__alert"
        variant="filled"
        severity="error"
      >
        Proszę wybrać opcje w każdej z podanych sekcji przed wyszukaniem
        polecanych materacy!
      </Alert>
    );

    const stepError = !selectedAnswer && (
      <Alert
        className="configurator-question-footer__alert"
        variant="filled"
        severity="error"
      >
        Aby przejść do następnego kroku należy odpowiedzieć na pytanie: "
        <strong>{question}</strong>"?
      </Alert>
    );

    return (
      <div className="configurator-question__error-wrapper">
        {activeStep === totalQuestionsCount ? generalError : stepError}
      </div>
    );
  };

  return (
    <div className={`configurator-question ${isActive ? 'configurator-question--active' : ''}`}>
      <div className="configurator-question__question-wrapper">
        <QuestionHeader
          isActive={isActive}
          questionId={id}
          toolTipDescription={description}
          name={question}
          displayOrder={displayOrder}
          selectedAnswer={selectedAnswer}
        >
          {isActive ? (
            <>
              <QuestionBody
                onAnswerSelected={onAnswerSelected}
                answers={answers}
                selectedAnswer={selectedAnswer}
                questionId={id}
                answerSelections={answerSelections}
              />
              <QuestionFooter
                answerSelections={answerSelections}
                question={question}
                onFormSubmit={onFormSubmit}
                totalQuestionsCount={totalQuestionsCount}
                selectedAnswer={selectedAnswer}
                activeStep={activeStep}
                onNextStep={onNextStep}
                onPreviousStep={onPreviousStep}
                isAlertShowed={isAlertShowed}
              />
            </>
          ) : null}
        </QuestionHeader>
        {isActive &&
          isAlertShowed &&
          answerSelections.length < activeStep &&
          showError()}
      </div>
    </div>
  );
}

export default Question;
