import React from 'react';
import "../../styles/Result/ResultParagraph.scss"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function ResultParagraph({description}) {
  return (
    <div className="configurator-result-paragraph">
      <p className="configurator-result-paragraph__text">
        <FiberManualRecordIcon className="configurator-result-paragraph__pointer"/>
        {description}
      </p>
    </div>
  );
}

export default ResultParagraph;