import * as React from "react";

function SvgIconArrowLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.003}
      height={15.753}
      {...props}
    >
      <g fill="#22262f" fillRule="evenodd">
        <path
          data-name="Path 1"
          d="M8.673.331a1.125 1.125 0 00-1.593 0L.33 7.081a1.125 1.125 0 000 1.593l6.75 6.75a1.126 1.126 0 101.593-1.593L2.718 7.877l5.955-5.954a1.125 1.125 0 000-1.593z"
        />
        <path
          data-name="Path 2"
          d="M18.002 7.877a1.125 1.125 0 00-1.125-1.125H2.252a1.125 1.125 0 100 2.25h14.625a1.125 1.125 0 001.125-1.125z"
        />
      </g>
    </svg>
  );
}

export default SvgIconArrowLeft;
