import React from 'react';
import "../../styles/Question/QuestionHeader.scss"
import CheckIcon from '@material-ui/icons/Check'
import SvgIconInfoOutline from '../Icons/IconInfoOutline'

function QuestionHeader({name, toolTipDescription, displayOrder, selectedAnswer, isActive, children}) {
    const displayOrderClassName = `configurator-question-header__display-order ${selectedAnswer ? 'configurator-question-header__display-order--grayed-out' : ''}`;

  return (
    <div className="configurator-question-header">
      <div
        className={!toolTipDescription ? "configurator-question-header__left-side configurator-question-header__left-side--without-tooltip" : "configurator-question-header__left-side"}>
        {!isActive && selectedAnswer ?
          <CheckIcon fontSize='large' className="configurator-question-header__check-icon"/> :
          <p className={displayOrderClassName}>{displayOrder}.</p>
        }
        <h3
            className={selectedAnswer ? "configurator-question-header__heading" : "configurator-question-header__heading configurator-question-header__heading--grayed-out"}>
            {name}
        </h3>
        {toolTipDescription && <div className="configurator-question-header__tooltip">
          <SvgIconInfoOutline
            className={name && name.length > 25 ? "configurator-question-header__info-icon configurator-question-header__info-icon--lower-margin" : "configurator-question-header__info-icon"}/>
          <span
            className={toolTipDescription.length > 25 ? "configurator-question-header__tooltiptext configurator-question-header__tooltiptext--large" : "configurator-question-header__tooltiptext"}>{toolTipDescription}</span>
        </div>}
      </div>
            {children}
    </div>
  );
}

export default QuestionHeader;
