import React from 'react';
import "../../styles/Question/QuestionBody.scss"
import {getModificatorBySelectedAnswer} from "../../lib/getModificatorBySelectedAnswer";

function QuestionBody({answers, questionId, selectedAnswer, onAnswerSelected, answerSelections}) {
  return (
    <div className="configurator-question-body">
      {answers.map((answer) =>
        answer.relatedAnswer ?
          answerSelections.map((answerId) => answerId.answerId == answer.relatedAnswer ?
            <button
              key={answer.id} onClick={() => onAnswerSelected({
              questionId: questionId,
              answerId: answer.id,
              relatedAnswer: answer.relatedAnswer
            })}
              className={answer.description !== null ?
                `configurator-question-body__button configurator-question-body__button--large ${getModificatorBySelectedAnswer(answer, selectedAnswer)}` :
                `configurator-question-body__button configurator-question-body__button--small ${getModificatorBySelectedAnswer(answer, selectedAnswer)}`}
            >
              <p> {answer.answer}</p>
                <p>{answer.description ? answer.description : null}</p>
            </button> : null
          ) : <button
            key={answer.id} onClick={() => onAnswerSelected({
            questionId: questionId,
            answerId: answer.id,
            relatedAnswer: answer.relatedAnswer
          })}
            className={answer.description !== null ?
              `configurator-question-body__button configurator-question-body__button--large ${getModificatorBySelectedAnswer(answer, selectedAnswer)}` :
              `configurator-question-body__button configurator-question-body__button--small ${getModificatorBySelectedAnswer(answer, selectedAnswer)}`}
          >
                <h4 className="button-title"> {answer.answer}</h4>
          </button>)}
    </div>
  );
}

export default QuestionBody;
