export const fetchQuestions = async () => {
  const url1 = window.storage.getQuestionsEndpoint;
  const url = '/configurator/questions';
  // const url = './json/get-questions.json'
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export const fetchResponse = async (parameters) => {
  /*const url = 'http://localhost/configurator/find-matching-product-variants';
  window.location = url + '?parameters=' + JSON.stringify(parameters);*/

  //const url = window.storage.getResultsEndpoint;
  // const url = './json/post-answers-response.json'
  const url = '/configurator/find-matching-product-variants';
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(parameters)
  };
  const response = await fetch('/configurator/find-matching-product-variants', requestOptions);
  const data = await response.json();
  return data.results;
};
