import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';

const root = document.getElementById('sembella-configurator');

ReactDOM.render(
  <React.StrictMode>
    <App {...(root.dataset)} />
  </React.StrictMode>,
  root
)
