import * as React from "react";

function SvgIconArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.003}
      height={15.753}
      {...props}
    >
      <g fill="#f0f1f5" fillRule="evenodd">
        <path
          data-name="Path 1"
          d="M9.329.331a1.125 1.125 0 011.593 0l6.75 6.75a1.125 1.125 0 010 1.593l-6.75 6.75a1.126 1.126 0 11-1.593-1.593l5.955-5.954-5.955-5.954a1.125 1.125 0 010-1.593z"
        />
        <path
          data-name="Path 2"
          d="M0 7.877a1.125 1.125 0 011.125-1.125H15.75a1.125 1.125 0 010 2.25H1.125A1.125 1.125 0 010 7.877z"
        />
      </g>
    </svg>
  );
}

export default SvgIconArrowRight;
