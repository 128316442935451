import React, {useEffect, useState} from "react";
import {fetchQuestions, fetchResponse} from "../lib/apiClient";
import Question from "./Question/Question";
import "../styles/Form.scss";
import ResultContainer from "./Result/ResultContainer";
import CircularProgress from "@material-ui/core/CircularProgress";

function Form() {
  const [questions, setQuestions] = useState([]);
  let [answerSelections, setAnswerSelections] = useState([]);
  let [activeStep, setActiveStep] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isAlertShowed, setIsAlertShowed] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    function fetchData() {
      setTimeout(async () => {
        setQuestions(await fetchQuestions());
      }, 2000);
    }

    fetchData();
  }, []);

  const onAnswerSelected = (answerSelection) => {
    const filteredSelections = answerSelections.filter(
      (selection) => selection.questionId !== answerSelection.questionId
    );
    setAnswerSelections([...filteredSelections, answerSelection]);
  };

  const onPreviousStep = () => {
    setIsAlertShowed(false);
    answerSelections.splice(activeStep - 2);
    setActiveStep(activeStep - 1);
  };

  const onNextStep = async () => {
    if (
      !answerSelections.find((selection) => selection.questionId === activeStep)
    ) {
      setIsAlertShowed(true);
    } else {
      setIsAlertShowed(false);
      answerSelections[activeStep] && answerSelections.splice(activeStep);
      setActiveStep(activeStep + 1);
    }
  };

  const onFormSubmit = async () => {
    if (answerSelections.length < activeStep) {
      setIsAlertShowed(true);
    } else if (answerSelections.length > 0) {
      setIsFormSubmitted(true);
      setActiveStep(activeStep + 1);
      setTimeout(async () => {
        const results = await fetchResponse(answerSelections);
        setResults(results);
      }, 2000);
    }
  };

  const searchAgain = () => {
    window.location.href = "#sembella-configurator";
      setActiveStep(1);
    setIsFormSubmitted(false);
    setAnswerSelections([]);
    setResults([]);
    setIsAlertShowed(false);
  };

  return (
    <>
      {questions.length === 0 ? (
        <CircularProgress className="configurator-form__loader" />
      ) : (
        <div className="configurator-form">
          <div className="configurator-form__header">
            <div className="configurator-form__header-mask">
              <h2 className="configurator-form__heading">
                Materac dopasowany z myślą o Tobie.
              </h2>
              <p className="configurator-form__paragraph">
                Odpowiedz na kilka pytań, a podpowiemy ci najlepsze rozwiązania!
              </p>
            </div>
          </div>

          {questions.map((question) => (
            <Question
              key={question.id}
              {...question}
              isFormSubmitted={isFormSubmitted}
              onFormSubmit={onFormSubmit}
              totalQuestionsCount={questions.length}
              onAnswerSelected={onAnswerSelected}
              isActive={activeStep === question.displayOrder}
              answerSelection={answerSelections.find(
                (selection) => selection.questionId === question.id
              )}
              answerSelections={answerSelections}
              activeStep={activeStep}
              onNextStep={onNextStep}
              onPreviousStep={onPreviousStep}
              isAlertShowed={isAlertShowed}
            />
          ))}

          {isFormSubmitted &&
            (results.length === 0 ? (
              <CircularProgress className="configurator-form__loader configurator-form__loader--result" />
            ) : (
              <ResultContainer searchAgain={searchAgain} results={results} />
            ))}
        </div>
      )}
    </>
  );
}

export default Form;
