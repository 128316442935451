import React from 'react';
import ResultHeader from "./ResultHeader";
import "../../styles/Result/ResultContainer.scss"
import ResultDescription from "./ResultDescription";
import ResultCardSection from "./ResultCardSection";
import {ResultFooter} from "./ResultFooter";

function ResultContainer({results, searchAgain}) {
  return (
    <div className="configurator-results">
      <ResultHeader answerNumber={results.length}/>
      <ResultDescription/>
      <ResultCardSection answers={results}/>
      <ResultFooter searchAgain={searchAgain}/>
    </div>
  );
}

export default ResultContainer;