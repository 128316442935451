import React from 'react';
import ResultCard from "./ResultCard";
import "../../styles/Result/ResultCardSection.scss"

function ResultCardSection({answers}) {
  return (
    <div className="configurator-result-card-section">
      {answers.map(answer => <ResultCard key={answer.id} {...answer}/>)}
    </div>
  );
}

export default ResultCardSection;