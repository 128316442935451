import './styles/App.scss';
import Form from "./components/Form";
import React from "react";

function App({ getQuestionsEndpoint, getResultsEndpoint }) {
  window.storage = { getQuestionsEndpoint, getResultsEndpoint }
  
  return (
    <div className="app">
      <Form />
    </div>
  );
}

export default App;
